import React, { useState, useEffect } from 'react';

import { getDatabase } from '../services/firebase'

let minutesPerBatch = 5;
let burgersPerBatch = 3;

function WaitInformation({currentOrder}) {
  const [allOrders, setAllOrders] = useState([]);
  const [numberOfBurgersAhead, setNumberOfBurgersAhead] = useState(0);
  const [waitTime, setWaitTime] = useState(minutesPerBatch);

  useEffect(()=>{
    getDatabase().then((database)=>{
      database.collection("orders")
        .onSnapshot((snapshot) => {
          setAllOrders(snapshot.docs.map((doc) => Object.assign({}, doc.data(), { id: doc.id }) ))
      });
    })
  },[currentOrder])

  useEffect(()=>{
    console.log("currentOrder.createdAt");
    console.log(currentOrder);
    setNumberOfBurgersAhead(
      allOrders
        .filter((order) => ["Order Placed", "Being Prepared"].indexOf(order.status) > -1)
        .filter((order) => order.createdAt < currentOrder.createdAt)
        .length
    )
  },[allOrders])

  useEffect(()=>{
    console.log("RECALULATING");
    if(numberOfBurgersAhead >= burgersPerBatch) {
      setWaitTime(Math.ceil((numberOfBurgersAhead / burgersPerBatch ) * minutesPerBatch + (numberOfBurgersAhead % minutesPerBatch > 0 ? 0 : minutesPerBatch)));
    }
  },[numberOfBurgersAhead])

  return  (
    <div className="waiting-stats-container">
      <div className="waiting-stats-block">
        <div className="waiting-stats-value">
          {numberOfBurgersAhead}
        </div>
        <div className="waiting-stats-title">
          Burgers Ahead of You
        </div>
      </div>
      <div className="waiting-stats-block">
        <div className="waiting-stats-value">
          {waitTime} minutes
        </div>
        <div className="waiting-stats-title">
          Approx Wait Time
        </div>
      </div>
      <div className="waiting-stats-block">
        <div className="waiting-stats-value">
          <TimeSinceOrdered createdAt={currentOrder.createdAt} />
        </div>
        <div className="waiting-stats-title">
          Time Since Order Placed
        </div>
      </div>
    </div>
  );
}

function TimeSinceOrdered({createdAt}) {
  const [timeSinceOrder, setTimeSinceOrder] = useState(0)
  useEffect(()=>{
    if(createdAt) setTimeSinceOrder(timeSince(createdAt))
    setInterval(() => setTimeSinceOrder(timeSince(createdAt)), 30000)
  },[createdAt])

  return `${timeSinceOrder} minutes`;
}

function timeSince(createdAt) {
  if (!createdAt) return 0;
  let diffMs = (new Date() - createdAt.toDate());
  return Math.round(((diffMs % 86400000) % 3600000) / 60000);
}

export default WaitInformation
