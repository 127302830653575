import React, { useState, useEffect, useContext } from 'react';
import Link from 'gatsby-link'

import { getDatabase } from '../services/firebase'

import { UserContext } from '../context/UserContext'

function PreviousOrders(props) {
  const user = useContext(UserContext);

  const [orders, setOrders] = useState();
  useEffect(()=>{
    if(user){
      getDatabase().then((database)=>{
        database.collection("orders")
          .where("userId", "==", user.uid)
          .onSnapshot((snapshot) => {
          setOrders(snapshot.docs.map((doc) => Object.assign({}, doc.data(), { id: doc.id }) ));
        });
      })
    }
  },[user])

  return  (
    <div>
      <br/>
      <h2 className="is-size-5 has-text-weight-bold is-bold-light">Your Previous Order</h2>
      {orders && orders.map((order) => {
        return <div key={order.id}>
          <Link to={`/order-status?id=${order.id}`}>{order.createdAt && formattedDate(order.createdAt.toDate())}</Link>
        </div>
      })}
    </div>
  );
}

function formattedDate(date) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return `${date.toLocaleDateString("en-US", options)} ${date.toLocaleTimeString()}`;
}

export default PreviousOrders
