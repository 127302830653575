import React, { useState, useEffect } from 'react';
import Link from 'gatsby-link'
import { OrderStatusMap } from '../constants/orderStatusMap'

import OrderDetails from '../components/OrderDetails'
import WaitInformation from '../components/WaitInformation'
import OrderAgainButton from '../components/OrderAgainButton'

import { getDatabase } from '../services/firebase'

function OrderStatusIndicator(props) {
  const orderId = props.orderId;

  const [order, setOrder] = useState({}); // [value1,value2]
  useEffect(()=>{
    getDatabase().then((database)=>{
      database.doc("orders/" + orderId).onSnapshot((doc) => {
        setOrder(doc.data());
      });
    })
  },[orderId])

  return  (
    <div>
      <h2 className="is-size-4 has-text-weight-bold is-bold-light">
        Hi {order.name}!
      </h2>
      <br/>
      <ProgressBar status={order.status} />
      {order.status !== "Picked Up" &&
        <WaitInformation currentOrder={order}/>
      }
      <br/>
      <OrderDetails order={order} />
      {order.status === "Picked Up" &&
        <div>
          <h2 className="is-size-5 has-text-weight-bold is-bold-light">
            Enjoy your burger.
          </h2>
          <Link className="btn" to="/order">New</Link>
          <OrderAgainButton orderId={orderId}>
            <button className="btn">Order Again</button>
          </OrderAgainButton>
        </div>
      }
    </div>
  );
}

function ProgressBar({status}) {
  let percentage = (OrderStatusMap[status] - 1)/2*100;
  if(percentage > 100) percentage = 100;
  return (
    <div style={{
      backgroundColor: "#eee",
      padding: "15px 30px 30px",
      border: "1px solid green",
      color: "green"
    }}>
      <div className="has-text-centered" style={{marginBottom: "15px"}}>Your Order is <span style={{color: "green", fontWeight: "700"}}>{status}</span></div>
      <progress className="progress is-large is-success" value={status ? percentage : 0} max="100">{percentage}%</progress>
    </div>
  )
}

export default OrderStatusIndicator
