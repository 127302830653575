import React from 'react';
import queryString from 'query-string'
import Layout from '../components/Layout'
import OrderStatusIndicator from '../components/OrderStatusIndicator'
import PreviousOrders from '../components/PreviousOrders'

function OrderStatusPage(props) {
  const orderId = queryString.parse(props.location.search).id;

  return  <Layout>
            <section
              className="section section--gradient"
              style={{
                color: 'black'
              }}
            >
              <div className="container">
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    <OrderStatusIndicator orderId={orderId}/>
                    <PreviousOrders />
                  </div>
                </div>
              </div>
            </section>
          </Layout>
}

export default OrderStatusPage
