import React from 'react';
import Lodash from 'lodash'

function OrderDetails({order}) {
  return  (
    <div>
      <h4 className="is-size-4 has-text-weight-bold is-bold-light">
        Your Burger:
      </h4>
      <ul>
        A {order.numberOfPattys} Burger with {order.slicesOfCheese === "2" ? '2 Slices of Cheese' : order.slicesOfCheese === "1" ? '1 Slice of Chees' : 'No Cheese' } and 
        {Object.entries(order)
          .filter(([key, value]) => value === "false" || value === "true"  )
          .map(([key, value]) => {
            if(key === 'createdAt' || key === 'userId' || key === 'status' || key === 'name' || value === 'false') return null;
            else return <span> {Lodash.startCase(key)}</span>;
        })}
      </ul>
    </div>
  );
}

export default OrderDetails
