import React, { useState, useContext, useEffect } from 'react'
import { navigate } from "gatsby"

import { getDatabase, getFireStore } from '../services/firebase'

import { UserContext } from '../context/UserContext'

function OrderAgainButton(props) {
  const orderId = props.orderId;
  const user = useContext(UserContext);
  const [order, setOrder] = useState({});
  useEffect(()=>{
    getDatabase().then((database)=>{
      database.doc("orders/" + orderId).onSnapshot((doc) => {
        setOrder(doc.data());
      });
    })
  },[orderId])

  function handleClick(e) {
    getFireStore().then((firestore)=>{
      const timestamp = firestore.FieldValue.serverTimestamp();
      firestore().collection("orders").add(
        {
          name: order.name,
          email: order.email,
          numberOfPattys: order.numberOfPattys,
          slicesOfCheese: order.slicesOfCheese,
          withMayo: order.withMayo,
          withGrilledOnions: order.withGrilledOnions,
          withRawOnions: order.withRawOnions,
          withJalepenos: order.withJalepenos,
          lettuceWrap: order.lettuceWrap,
          status: 'Order Placed',
          userId: user.uid,
          createdAt: timestamp
        }
      )
      .then(function(docRef) {
        navigate("/order-status/?id=" + docRef.id)
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
    })
    e.preventDefault();
  }

  return (
    <div
      onClick={handleClick}
      disabled={order}
    >
      {props.children || "Order Again"}
    </div>
  )
}

export default OrderAgainButton;
